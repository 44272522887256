import { ComposableClient } from "@becomposable/client";
import { onAuthStateChanged } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { firebaseAuth, getFirebaseAuthToken } from "./auth/firebase";

import { UserNotFoundError, getComposableToken } from "./auth/composable";
//import useUXTracking from "./useUXTracking";
import { LastSelectedAccountId_KEY, LastSelectedProjectId_KEY, UserSession, UserSessionContext } from "./UserSession";


function switchAccount(client: ComposableClient, accountId: string) {
    return client.post('/auth/switch-account', {
        payload: {
            accountId: accountId
        }
    }).then(() => {
        window.location.assign('/');
    });
}

interface UserSessionProviderProps {
    children: ReactNode | ReactNode[];
}
export function UserSessionProvider({ children }: UserSessionProviderProps) {
    //const { tagUserSession, trackEvent } = useUXTracking();

    const [session, setSession] = useState<UserSession>(new UserSession());

    useEffect(() => {
        return onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
            if (firebaseUser) {
                console.log('Auth: successful login with firebase');
                session.setSession = setSession;

                const selectedAccount = localStorage.getItem(LastSelectedAccountId_KEY) ?? undefined
                const selectedProject = localStorage.getItem(LastSelectedProjectId_KEY) ?? undefined
                console.log('Auth: selected account', selectedAccount);
                console.log('Auth: selected project', selectedProject);

                await getComposableToken(getFirebaseAuthToken, selectedAccount, selectedProject).then(res => {
                    console.log('Auth: fetched user token from studio', res.token);
                    const token = res?.token;
                    if (!token) {
                        throw new Error('No token available');
                    }

                    if (!token.accounts || token.accounts.length === 0) {
                        throw new Error('No accounts available');
                    }

                    session.login(res.rawToken).then(() => setSession(session.clone()));

                }).catch(err => {
                    console.error('Failed to fetch user token from studio', err);
                    if (!(err instanceof UserNotFoundError)) session.logout();
                    session.isLoading = false;
                    session.authError = err;
                    setSession(session.clone());
                });

            } else {
                // anonymous user
                console.log('Auth: using anonymous user');
                session.client.withAuthCallback(undefined);
                session.logout();
                setSession(session.clone());
            }
        });
    }, []);


    return (
        <UserSessionContext.Provider value={session}>{children}</UserSessionContext.Provider>
    );

}
