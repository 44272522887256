const env = import.meta.env;

const isLocalDev = window.location.host.includes('localhost');

//location: https://preview.cloud.becomposable.com
const host = window.location.host.split('.');
const isProd = host.length === 3 && host[0] === "cloud";
const backendBase = env.VITE_API_BACKEND_BASE;
const envType = isProd ? "production" : (isLocalDev ? "development" : host[0]);

const getServerUrl = (service: string, env: string) => {
    //const params = useParams();

    if (isLocalDev) {
        if (service === "studio") {
            return `http://127.0.0.1:8091`;
        }
        if (service === "zeno") {
            return `http://127.0.0.1:8092`;
        }
    }

    if (!backendBase) {
        throw new Error('VITE_API_BACKEND_BASE not set');
    }

    return `https://${service}-server-${env}.${backendBase}`;

    // const qs = window.location.search;
    // const params = new URLSearchParams(qs);
    // const domain = params.get('domain');

    // if (domain === "reset") {
    //     localStorage.removeItem("domain");
    //     return serverUrl;
    // }

    // if (domain) {
    //     localStorage.setItem("domain", domain);
    //     return `https://${domain}`;
    // }

    // if (localStorage.getItem("domain")) {
    //     return `https://${localStorage.getItem("domain")}`;
    // }

};

const getBaseUrl = () => {

    if (env.BASE_URL) {
        return env.BASE_URL
    }

    return `https://${window.location.host}`;

}

function getAuthDomain() {
    if (isLocalDev && typeof window !== 'undefined') {
        // use current host since vite may change the port
        console.log('Using local dev auth domain:', window.location.host);
        return window.location.host;
    }

    return window.location.host;
}

const authDomain = getAuthDomain();

const Env = {
    version: __VERCEL_ENV_COMMIT_SHA__,
    isProd: isProd,
    isDev: !isProd,
    isPreview: envType === "preview",
    isLocalDev,
    type: envType,
    BASE_URL: getBaseUrl(),
    endpoints: {
        zeno: getServerUrl('zeno', envType),
        studio: getServerUrl('studio', envType),
    },
    API_PATH: `/api/v1`,
    firebase: {
        apiKey: env.VITE_FIREBASE_API_KEY,
        authDomain,
        projectId: "dengenlabs",
    },
    sentry: {
        dsn: env.VITE_SENTRY_DSN
    },
    datadog: env.DATADOG_RUM_ENABLED !== "false", // enabled by default
};

if (!Env.isProd) {
    console.log("Initializing App with Env", Env);
}

export default Env;