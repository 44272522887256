//Create form for a new API Key

import { ApiKeyTypes, ApiKeyWithValue, CreateOrUpdateApiKeyPayload, ProjectRef, ProjectRoles } from "@becomposable/common";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalTitle, SelectBox } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useUserSession } from "../../session/UserSession";
import useUXTracking from "../../session/useUXTracking";

const ApiKeyRoles = Object.values(ProjectRoles).filter(role => role !== ProjectRoles.owner && role !== ProjectRoles.billing)

interface APIKeyCreateModalProps {
    isOpen: boolean
    onClose: () => void
    setActiveKey: (key: ApiKeyWithValue | undefined) => void
    setRefetch: (r: boolean) => void
}

export default function APIKeyCreateModal({ isOpen, onClose, setActiveKey, setRefetch }: APIKeyCreateModalProps) {
    const [expireIn, setExpireIn] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false)
    const { account, project, client } = useUserSession();
    const { trackEvent } = useUXTracking();
    const [selectedProject, setSelectedProject] = useState(project);

    const { data: projects } = useFetch(() => client.projects.list([account!.id]), [account?.id])

    const [payload, setPayload] = useState<CreateOrUpdateApiKeyPayload>({
        name: "Key Created On " + dayjs().format("YYYY-MM-DD HH:mm:ss"),
        role: ProjectRoles.developer,
        type: ApiKeyTypes.secret,
        project: selectedProject?.id,
        enabled: true
    })

    const onSelectProject = (project: ProjectRef) => {
        setSelectedProject(project);
        updatePayload("project", project.id);
    }

    const updatePayload = (field: string, value: any) => {
        setPayload({ ...payload, [field]: value })
    }

    const createKey = () => {
        let expire = expireIn.trim();
        if (expire) {
            const m = /^([1-9][0-9]*)\s*([smhdM])$/.exec(expire);
            if (!m) {
                window.alert('Invalid "expire in" format: ' + expire);
                return;
            }
            payload.expires_at = dayjs().add(parseInt(m[1]), m[2] as any).toDate();
        }
        setIsLoading(true)
        client.apikeys.create(payload).then((key) => {
            trackEvent('apikey_create', { role: key.role, type: key.type });
            setActiveKey(key);
        }).finally(() => {
            setIsLoading(false);
            setRefetch(true);
            onClose();
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalTitle>Create New API Key</ModalTitle>
            <ModalBody>
                <FormItem className="" label="Name">
                    <Input className="w-full" value={payload.name} onChange={(value) => updatePayload('name', value)} />
                </FormItem>
                <div className="mt-4">
                    <FormItem label="Role">
                        <RoleSelector value={payload.role} onChange={(value) => updatePayload('role', value)} type={payload.type} />
                    </FormItem>
                    <div className="mt-4">
                        <FormItem label="Type">
                            <SelectBox options={Object.values(ApiKeyTypes)}
                                value={payload.type}
                                optionLabel={(option) => option === "pk" ? "Public Key (pk)" : "Secret Key (sk)"}
                                onChange={(value) => updatePayload('type', value)}
                            />
                        </FormItem>
                    </div>
                    <div className="mt-4">
                        <FormItem label="Project">
                            {project &&
                                <SelectBox
                                    by="name"
                                    className={clsx('w-auto')}
                                    value={selectedProject}
                                    options={projects}
                                    optionLabel={(option) => option?.name}
                                    onChange={onSelectProject} />
                            }
                        </FormItem>
                    </div>
                    <div className="mt-4">
                        <FormItem label="Expire in">
                            <Input value={expireIn} onChange={(value) => setExpireIn(value)} />
                            <div className='text-gray-800 text-sm pt-1'>
                                You should use an integer followed by an unit type which can be one of:
                                <i> s for seconds, m for minutes, h for hours, d for days and M for months</i>.
                            </div>
                        </FormItem>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="primary" onClick={createKey} isDisabled={isLoading}>Create</Button>
            </ModalFooter>

        </Modal >
    )
}


interface ShowKeyValueModalProps {
    activeKey?: ApiKeyWithValue
    setActiveKey: (key: ApiKeyWithValue | undefined) => void
}
export function ShowKeyValueModal({ activeKey, setActiveKey }: ShowKeyValueModalProps) {

    return (

        <Modal isOpen={!!activeKey} onClose={() => setActiveKey(undefined)}>
            <ModalTitle>{activeKey?.name}</ModalTitle>
            <ModalBody className="w-auto">
                <p className="px-2 text-sm py-2 text-slate-800 dark:text-slate-50">
                    Here is your API Key.
                    Please be very careful with this key, as it can be used to access your account.
                    If you have any concerns about its safety, you can disable this key at any time.
                </p>
                <div className="px-2 py-2 my-4 text-center border-2 bg-slate-800 text-slate-200">
                    <pre>{activeKey?.value}</pre>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={() => setActiveKey(undefined)}>Close</Button>
            </ModalFooter>
        </Modal >


    )

}

function FormItem({ label, children, className }: { label: string, className?: string, children: any }) {
    return (
        <div className={clsx("mt-6 mb-2", className)} >
            <label htmlFor="name" className="mb-2 block text-sm text-slate-800 dark:text-slate-50 font-semibold">
                {label}
            </label>
            {children}
        </div >
    )
}


interface RoleSelectorProps {
    type?: ApiKeyTypes;
    value: ProjectRoles | undefined;
    onChange: (value: ProjectRoles | undefined) => void;
}
function RoleSelector({ type, value, onChange }: RoleSelectorProps) {
    useEffect(() => {
        if (type === ApiKeyTypes.public) {
            onChange(ProjectRoles.executor);
        }
    }, [type])
    return (
        <SelectBox
            disabled={type === ApiKeyTypes.public}
            options={Object.values(ApiKeyRoles).map((role) => ({ label: role, value: role }))}
            value={{ label: value, value }}
            optionLabel={(option) => option.label}
            onChange={(value) => onChange(value.value)}
        />
    )
}