import { createContext, useContext } from 'react';

import { ComposableClient, ComputeFacetsResponse, ZenoClient } from '@becomposable/client';
import { ComplexSearchQuery, ContentObjectItem, FacetBucket, FacetSpec, ObjectSearchQuery } from '@becomposable/common';
import { SharedState, useWatchSharedState } from '@reactik/hooks';

interface SearchResult {
    objects: ContentObjectItem[],
    error?: Error;
    isLoading: boolean;
}


export class Search {

    facets = new SharedState<ComputeFacetsResponse>({});
    result = new SharedState<SearchResult>({ objects: [], isLoading: false });

    facetSpecs: FacetSpec[] = [];
    query: ComplexSearchQuery = {};

    constructor(public client: ComposableClient | ZenoClient, public limit = 100) { }

    withFacets(facets: FacetSpec[]) {
        this.facetSpecs = facets;
        return this;
    }

    get objects() {
        return this.result.value.objects;
    }

    get error() {
        return this.result.value.error;
    }

    get isRunning() {
        return this.result.value.isLoading;
    }

    getFilterValue(name: string) {
        return (this.query as any)[name];
    }

    setFilterValue(name: string, value: any) {
        (this.query as any)[name] = value;
        // search now
        this.search();
    }

    getFacetBuckets(name: string): FacetBucket[] {
        return (this.facets.value as any)[name]?.buckets || [];
    }

    reset(isLoading = false) {
        this.result.value = {
            objects: [],
            isLoading
        };
    }

    _updateRunningState(value: boolean) {
        const prev = this.result.value;
        this.result.value = {
            objects: prev.objects,
            isLoading: value,
            error: prev.error
        }
    }

    computeFacets(query: ObjectSearchQuery) {
        this.client.objects.computeFacets({
            facets: this.facetSpecs,
            query
        }).then((facets) => {
            this.facets.value = facets;
        });
    }

    _search(loadMore = false) {
        if (this.isRunning) { // avoid searching when a search is pending
            return Promise.resolve(false);
        }
        this.result.value = {
            isLoading: true,
            objects: loadMore ? this.objects : [],
        }
        const limit = this.limit;
        const offset = this.objects.length;
        return this.client.objects.search({
            limit,
            offset,
            query: this.query
        }).then(async (res) => {
            this.result.value = {
                isLoading: false,
                objects: this.objects.concat(res)
            }
            return true;
        }).catch((err) => {
            this.result.value = {
                error: err,
                isLoading: false,
                objects: this.objects
            }
            throw err;
        })
    }

    search(noFacets = false) {
        if (this.isRunning) return Promise.resolve(false);
        !noFacets && this.computeFacets(this.query);
        return this._search(false);
    }

    loadMore(noFacets = false) {
        if (this.isRunning) return Promise.resolve(false);
        if (this.objects.length === 0) {
            !noFacets && this.computeFacets(this.query);
        }
        return this._search(true);
    }
}

const SearchContext = createContext<Search | undefined>(undefined);

export function useSearch() {
    return useContext(SearchContext)!;
}

export function useWatchSearchFacets() {
    return useWatchSharedState(useSearch().facets);
}

export function useWatchSearchResult() {
    const search = useSearch();
    const result = useWatchSharedState(search.result);
    return { ...result, search };
}

export function useSearchCount() {
    const search = useSearch();
    const result = useWatchSharedState(search.facets);
    return result.total;
}


export { SearchContext };
