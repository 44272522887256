import React, { ChangeEvent } from "react";
import { Styles } from "./styles.js";
import clsx from "clsx";

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'value'> {
    value?: string;
    onChange?: (value: string) => void
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({ value, onChange, className, type, ...others }: InputProps, ref) => {
    const _onChange = (ev: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(ev.target.value)
    }

    return (
        <input
            type={type || 'text'}
            value={value == null ? '' : value}
            onChange={_onChange}
            className={clsx('w-full', Styles.INPUT, className)}
            ref={ref}
            {...others}
        />

    )
})

export { Input };
